import { css } from '@emotion/react'
import React from 'react'
import { Component, memo } from 'react';
import loadable from "@loadable/component"
import {
    Container,
    Typography,
    Box,
    Grid
} from '@material-ui/core';
import { withThemeProps } from '@findep/microfronts-core'
import banner_main from '../../assets/images/afi/medios-de-pago-AFI.png'
import banner_sub from '../../assets/images/afi/H-AFI-02.jpg'
import banner_sub_in from '../../assets/images/afi/in-afi.jpg'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckIcon from '@material-ui/icons/Check';

// import CardBenefits from '../afi/CardBenefits'

const SimuladorCard = loadable(() => import('../pages/SimuladorCard'))
// const CarouselCard = loadable(() => import('../carousel/CarouselCard'))

const title_pry = css`
    color: #54595F;
    font-family: "Poppins", Sans-serif;    
    font-size: 30px;
    font-weight: 600;
`
const subtitle_pry = css`
    color: #928d85;
    font-family: "Poppins", Sans-serif;    
    font-size: 16px;
    font-weight: 400;
`
// color: rgba(255, 255, 255, 0.87);
// text-shadow: 0 2px 4px #363534;
class Simulador extends Component {    

    componentDidMount(){
        console.log(this.props.pageContext?.locale)
    }

    render() {
        const { 
            slogan,
            subtitle,
            textAplicapri="Aplica en línea",
            textDebito="Recibe tu préstamo directo en tu cuenta de débito",
            textPagos="La comodidad para hacer tus pagos",
            textAcceso="Acceso a tu cuenta las 24 horas del día desde donde te encuentres a través de nuestra APP móvil.",
            textPenalizacion="No hay penalización",
            textAnticipados="Por pagos anticipados.",   
            
            
        } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Box component="span" m={1} >
                            <span css={title_pry}>
                                {slogan('AFI')}
                            </span>
                            <br/>
                            <span css={subtitle_pry}>
                                {subtitle}
                            </span>
                            
                        
                        <br /><br/>
                        <SimuladorCard pageContext={this.props.pageContext} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                       
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img src={banner_main}   rel="nofollow noopener noreferrer" alt="in-icon" width='100%'/>                    
                    </Grid>

                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                        <Grid item xs={12} sm={1}></Grid>
                        <Grid item xs={12} sm={5}>
                        <img src={this.props.pageContext?.locale==="esES"? banner_sub: banner_sub_in}   rel="nofollow noopener noreferrer" alt="in-icon" width='100%'/>
                        </Grid>
                        <Grid item xs={12} sm={1}></Grid>
                        <Grid item xs={12} sm={5}>
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                <ListItem>
                                    <ListItemIcon><CheckIcon color='primary' fontSize='large' /></ListItemIcon>
                                    <ListItemText primary={textAplicapri} secondary={textDebito}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckIcon color='primary' fontSize='large' /></ListItemIcon>
                                    <ListItemText primary={textPagos} secondary={textAcceso}/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon><CheckIcon color='primary' fontSize='large' /></ListItemIcon>
                                    <ListItemText primary={textPenalizacion} secondary={textAnticipados}/>
                                </ListItem>
                            </List>
                        </Grid>
                    </Box>

                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>                        
                        <Grid item xs={12} >
                            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                    <ListItem>
                                        <ListItemIcon><CheckIcon color='primary' fontSize='large' /></ListItemIcon>
                                        <ListItemText primary={textAplicapri} secondary={textDebito}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><CheckIcon color='primary' fontSize='large' /></ListItemIcon>
                                        <ListItemText primary={textPagos} secondary={textAcceso}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><CheckIcon color='primary' fontSize='large' /></ListItemIcon>
                                        <ListItemText primary={textPenalizacion} secondary={textAnticipados}/>
                                    </ListItem>
                                </List>
                        </Grid>                                                                                   
                    </Box>

                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>                                                                                   
                        <Grid item xs={12} >
                        <img src={this.props.pageContext?.locale==="esES"? banner_sub: banner_sub_in}    rel="nofollow noopener noreferrer" alt="in-icon" width='100%'/>
                        </Grid>                        
                    </Box>

                </Grid>
            </Container>
        );
    }
}

Simulador.defaultProps = {
    slogan: ()=> (<>El préstamo que necesitas,  fácil, rápido y seguro.</>),
    subtitle:'Nuestras soluciones inteligentes ayudan a fortalecer tus finanzas y a darte confianza.'
}

export default memo(withThemeProps(Simulador, 'VDNPSimulador'));