import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import Simulador from '../components/pages/SimuladorAfi'


// import Background from '../assets/images/afi/heroBG.jpg';
// const sectionStyle = {
//   backgroundImage: `url(${Background})`,
//   backgroundSize: "cover",
//   backgroundRepeat: "no-repeat",
//   backgroundPosition: "center center",
//   borderTopLeftRadius: "2rem",
//   borderTopRightRadius: "2rem",
//   paddingTop: "2.5rem",
// };

const SimuladorPage = ({ pageContext, location }) => {

  return (
    <LayoutsComponent
      broker={pageContext.broker}
      layout={pageContext.layout}
      pageContext={pageContext}
      companyName={pageContext.company}
      location={location}
      
      // menu
      render={() => (
        <>
          <SEO title={pageContext.title} />
          {/* <div style={sectionStyle}> */}
          <Simulador pageContext={pageContext} />
          {/* </div> */}
        </>
      )}
    />
  )
}

export default SimuladorPage
